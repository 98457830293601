import "./Title.css";
import React from "react";

export const Title = (props) => {
  return (
    <div className="title-block">
      <h1 className="first-title">{props.firstTitle}</h1>
      <h2 className="second-title">{props.secondTitle}</h2>
    </div>
  );
};
