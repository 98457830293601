import axios from "axios";
const url = "/api/cities";

export const axiosGetAllCities = async () => {
  let info;
  await axios.get(url).then((res) => {
    info = res.data;
  });
  return info;
};
