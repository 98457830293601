import "./ResponseType"

export class CandidateCampaignResponse {
    CandidateId;
    CampaignId;
    ResponseType;
    ReferrerId;
    ReferrerDetailId;
    SubReferrerDetailId;

    constructor(candidateId, campaignId, responseType, referrerId, referrerDetailId, subReferrerDetailId) {
        this.CandidateId = candidateId;
        this.CampaignId = campaignId;
        this.ResponseType = responseType;
        this.ReferrerId = referrerId;
        this.ReferrerDetailId = referrerDetailId;
        this.SubReferrerDetailId = subReferrerDetailId;
    }
}