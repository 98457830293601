export class DataRec {
  CandidateId;
  FullName;
  CityId;
  MobilePhone;
  Email;
  Gender;
  BirthDate;

  constructor(
    candidateId,
    fullName,
    cityId,
    mobilePhone,
    email,
    gender,
    birthDate
  ) {
    this.CandidateId = candidateId;
    this.FullName = fullName;
    this.CityId = cityId;
    this.MobilePhone = mobilePhone;
    this.Email = email;
    this.Gender = gender;
    this.BirthDate = birthDate;
  }
}
