import "./ServiceFooter.css";
import Button from "../Button/Button";
import Cookies from "js-cookie";
import { axiosSendEmail } from "../../Services/EmailService";

const ServiceFooter = (props) => {
  const interested = async () => {
    window.location =
      window.location.href.slice(0, window.location.href.indexOf("/", 10)) +
      "/thankyoucandidate";
    const result = await axiosSendEmail(
      props.candidateId,
      props.serviceOrderId,
      props.processId
    );
    if (result) {
      Cookies.set(props.serviceOrderId, { expires: 7 });
    } else {
      console.log("Error in interested notification!");
    }
  };

  const notInterested = async () => {
    window.location =
      window.location.href.slice(0, window.location.href.indexOf("/", 10)) +
      "/notinterested";
  };

  return (
    <div className="container footer-container">
      <div className="row footer-row">
        <div className="col interested-col">
          <Button
            path="InterestedCallMe.png"
            width="100%"
            height="100%"
            onClick={interested}
          />
        </div>
        <div className="col uninterested-col">
          <a href={`tel:${props.branchPhone}`}>
            <Button path="CallBranch.png" width="100%" height="100%" />
          </a>
        </div>
      </div>
      <div className="notInterested">
        <a href onClick={notInterested}>
          לא מתאים לי, הציעו לי משרות נוספות
          <i className="description-arrow left"></i>
        </a>
      </div>
    </div>
  );
};

export default ServiceFooter;
