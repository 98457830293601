import "./Preview.css";
import React, { useEffect, useState } from "react";
import { Paper } from "@material-ui/core";
import Description from "../../Components/Description/Description";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useParams } from "react-router-dom";
import { Title } from "../../Components/Title/Title";
import { createTemplateInstance } from "../../Classes/Templates/Template";

const Preview = () => {
  const [image, setImage] = useState(null);
  const [showFullText, setShowFullText] = useState(false);
  const [showContinue, setShowContinue] = useState(false);
  const [firstTitle, setFirstTitle] = useState(null);
  const [secondTitle, setSecondTitle] = useState(null);

  const { templateImage, jobTitle, description, demands } = useParams();

  const paperStyle = {
    height: "auto",
    width: "auto",
    maxWidth: "100%",
    margin: "2% 2%",
    boxShadow: "0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24)",
    borderRadius: "5px",
  };
  let templateInformation;

  const loadPage = async () => {
    templateInformation = {
      templateCode: templateImage,
      templateName: templateImage.substring(2),
    };
    await templateHandler(templateInformation);
    const textDiv = document.getElementsByClassName(
      "preview-job-information"
    )[0];
    if (textDiv.scrollHeight > textDiv.clientHeight) {
      setShowContinue(true);
    } else {
      setShowContinue(false);
    }
  };
  const templateHandler = async (templateInformation) => {
    var template = await createTemplateInstance(templateInformation);
    setImage(template.ImageIdentifier);
    setFirstTitle(template.FirstText);
    setSecondTitle(template.SecondText);
  };

  useEffect(() => {
    loadPage();
  }, []);

  return (
    <div className="container main-container preview-container">
      <Paper style={paperStyle}>
        <div className="col header">
          <div className="container headers-container">
            <div className="row justify-content-end header-row">
              <div className="col-8 logo-header">
                <img
                  src={require(`../../Images/Icons/Logo.png`)}
                  alt="Image"
                  loading="lazy"
                  width="85%"
                  className="logo-header-image"
                />
              </div>
              <div className="col-2 share-header">
                <img
                  src={require(`../../Images/Icons/ShareRed.png`)}
                  alt="Image"
                  loading="lazy"
                  width="40rem"
                  className="share-header-image"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col title">
          <Title firstTitle={firstTitle} secondTitle={secondTitle} />
        </div>
        <div className="col main-image">
          {image ? (
            <div>
              <img
                src={require(`../../Images/${image}`)}
                width="90%"
                height="100%"
              />
            </div>
          ) : null}
        </div>
        <div
          className={`preview-job-information ${
            showFullText
              ? "preview-full-job-information"
              : "preview-job-information"
          }`}
        >
          <Description title={jobTitle} body={description} demands={demands} />
        </div>
        {showContinue ? (
          <div
            className="col continue-button"
            onClick={() => setShowFullText(true)}
          >
            <div
              className={`show-continue ${
                showFullText ? `dont-show-continue` : "show-continue"
              }`}
            >
              להמשך<i className="description-arrow left"></i>
            </div>
          </div>
        ) : null}
        <div className="col footer">
          <div className="container footer-container">
            <div className="row footer-row">
              <div className="col interested-col">
                <LazyLoadImage
                  src={require(`../../Images/Icons/InterestedArrowText.png`)}
                  width="100%"
                  height="100%"
                  loading="lazy"
                />
              </div>
              <div className="col uninterested-col">
                <LazyLoadImage
                  src={require(`../../Images/Icons/NotInterestedArrowText.png`)}
                  width="100%"
                  height="100%"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        </div>
      </Paper>
    </div>
  );
};

export default Preview;
