import config from "../../configuration";

export class DischargedSoldier {
  FirstText;
  SecondText;
  ImageIdentifier;

  constructor(imageIdentifier) {
    this.FirstText = "השתחררת?";
    this.SecondText = "יש לנו משרה שבאה בטוב";
    this.setImageIdentifier(imageIdentifier);
  }

  async setImageIdentifier(imageIdentifier) {
    this.ImageIdentifier = await ImageHandler(imageIdentifier);
  }
}

const ImageHandler = async (imageIdentifier) => {
  if (imageIdentifier === config.DischargedSoldier1)
    return "DischargedSoldier/DischargedSoldier1.png";
  else if (imageIdentifier === config.DischargedSoldier2)
    return "DischargedSoldier/DischargedSoldier2.png";
  else throw "Wrong image identifier received.";
};
