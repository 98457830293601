import config from "../../configuration";

export class Academic {
  FirstText;
  SecondText;
  ImageIdentifier;

  constructor(imageIdentifier) {
    this.FirstText = "אקדמאי/ת";
    this.SecondText = "יש לנו משרה בדיוק בשבילך";
    this.setImageIdentifier(imageIdentifier);
  }

  async setImageIdentifier(imageIdentifier) {
    this.ImageIdentifier = await ImageHandler(imageIdentifier);
  }
}

const ImageHandler = async (imageIdentifier) => {
  if (imageIdentifier === config.Academic1) return "Academic/Academic1.png";
  else if (imageIdentifier === config.Academic2)
    return "Academic/Academic2.png";
  else if (imageIdentifier === config.Academic3)
    return "Academic/Academic3.png";
  else throw new Error("Wrong image identifier received.");
};
