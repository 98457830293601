export class NewCandidate {
  FullName;
  CityId;
  Phone;
  CampaignId;
  ServiceOrderId;
  ReferrerId;
  ReferrerDetailId;
  SubReferrerDetailId;

  constructor(
    fullName,
    cityId,
    phone,
    campaignId,
    serviceOrderId,
    referrerId,
    referrerDetailId,
    subReferrerDetailId
  ) {
    this.FullName = fullName;
    this.CityId = cityId;
    this.Phone = phone;
    this.CampaignId = campaignId;
    this.ServiceOrderId = serviceOrderId;
    this.ReferrerId = referrerId;
    this.ReferrerDetailId = referrerDetailId;
    this.SubReferrerDetailId = subReferrerDetailId;
  }
}
