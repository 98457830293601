import "./DataFooter.css";
import React, { useState, useEffect, CSSProperties } from "react";
import Select from "react-select";
import { axiosGetAllCities } from "../../Services/CitiesService";
import DatePicker, { registerLocale } from "react-datepicker";
import {
  axiosCvUpdateCandidate,
  axiosCandidateDataUpdate,
} from "../../Services/CandidateService";
import "react-datepicker/dist/react-datepicker.css";
import he from "date-fns/locale/he";
import BarLoader from "react-spinners/BarLoader";
import validator from "validator";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { axiosCorrectCandidate } from "../../Services/CandidateService";

const DataFooter = (props) => {
  const [isFormVisible, setFormVisible] = useState(false);
  const [fullName, setFullName] = useState("");
  const [city, setCity] = useState(null);
  const [email, setEmail] = useState(null);
  let [gender, setGender] = useState("");
  const [birthDate, setBirthDate] = useState(null);
  let [cities, setCities] = useState([]);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [candidate, setCandidate] = useState(null);

  registerLocale("he", he);
  let result;

  const handleFileChange = async (event) => {
    event.preventDefault();
    const allowedTypes = [
      "application/pdf",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ];
    setLoading(true);

    const file = event.target.files[0];
    const candidate = await loadCorrectCandidate();
    if (file && allowedTypes.includes(file.type) == false) {
      notify("קובץ מסוג זה לא נתמך");
      setLoading(false);
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = async () => {
      candidate.base64 = reader.result.split(",")[1];
      try {
        await axiosCvUpdateCandidate(candidate);
        window.location.assign(
          window.location.href.slice(0, window.location.href.indexOf("/", 10)) +
            "/candidate/keepInTouch"
        );
      } catch (error) {
        setLoading(false);
        notify("קובץ מסוג זה לא נתמך");
        console.log(error.response);
      }
    };
  };

  const candidateDataValidation = async () => {
    setLoading(true);
    const candidate = await loadCorrectCandidate();
    if (
      fullName !== "" &&
      city !== null &&
      city.label !== null &&
      gender !== "" &&
      ((email != "" && email != null && validator.isEmail(email)) ||
        email === null ||
        email === "") &&
      ((birthDate != "" && birthDate != null && validateBirthDate(birthDate)) ||
        birthDate === null ||
        birthDate === "")
    ) {
      if (gender == "100000000") gender = null;
      setMessage("");
      await axiosCandidateDataUpdate(
        candidate.contactId,
        fullName,
        city.value,
        candidate.mobilePhone,
        email,
        gender,
        birthDate
      );
      setLoading(false);
      window.location =
        window.location.href.slice(0, window.location.href.indexOf("/", 10)) +
        "/candidate/keepInTouch";
    } else if (fullName === "") {
      setMessage("הכנס/י שם מלא");
    } else if (city === null || city.label === null) {
      setMessage("הכנס/י עיר מגורים");
    } else if (gender === "") {
      setMessage("הכנס/י מין");
    } else if (
      email != "" &&
      email != null &&
      validator.isEmail(email) == false
    ) {
      setMessage("הכנס/י מייל תקין");
    } else if (
      birthDate != "" &&
      birthDate != null &&
      validateBirthDate(birthDate) == false
    ) {
      setMessage("הכנס/י תאריך לידה תקין");
    }
    setLoading(false);
  };

  const validateBirthDate = (birthDate) => {
    const oneYearAgo = new Date();
    oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);

    if (birthDate > oneYearAgo) return false;
    return true;
  };

  const handleCvUpload = () => {
    document.getElementById("resume-file-input").click();
  };

  const getAllCities = async () => {
    result = await axiosGetAllCities();
    if (result) {
      setCities(renderList(result));
    }
  };

  const handleFullNameOnChange = (e) => {
    setFullName(e.target.value);
  };

  const handleEmailOnChange = (e) => {
    var inputEmail = e.target.value;
    setEmail(inputEmail);
  };

  const handleBirthdateOnChage = (e) => {
    setBirthDate(e);
  };

  function renderList(cities) {
    result = cities
      .filter(
        (city) =>
          city.cityName != 'רילוקיישן לחו"ל' &&
          ((city) => city.code != undefined)
      )
      .map((data) => ({ label: data.cityName, value: data.cityId }));
    return result.sort(function (a, b) {
      const nameA = a.label.toUpperCase();
      const nameB = b.label.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
    });
  }

  const toggleFormVisibility = () => {
    setFormVisible(!isFormVisible);
  };

  const stopPropagation = (event) => {
    event.stopPropagation();
  };

  const customStyles = {
    control: (base) => ({
      ...base,
      height: "50%",
      fontSize: 16,
      color: "red",
      borderRadius: "0",
    }),
    menuList: (base) => ({
      ...base,
      maxHeight: 100,
    }),
  };

  const notify = (errorText) => {
    toast.error(errorText, {
      position: "top-center",
      autoClose: false,
      closeOnClick: true,
      draggable: false,
      closeButton: true,
      className: "custom-toast",
      bodyClassName: "custom-toast-body",
    });
  };

  const hideToast = () => {
    toast.dismiss();
  };

  const loadCorrectCandidate = async () => {
    const path = window.location.pathname;
    var result = await axiosCorrectCandidate(path.split("/candidate/")[1]);
    setCandidate(result);
    return result;
  };

  useEffect(() => {
    getAllCities();
  }, []);

  return (
    <div className="dataFooter-container">
      <BarLoader
        className="barLoader"
        color=" #e74a54"
        height={10}
        loading={loading}
      />
      <div className="data-col">
        <input
          type="file"
          id="resume-file-input"
          accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          onChange={handleFileChange}
          style={{ display: "none" }}
        />
        <div onClick={hideToast} className="dark-overlay">
          <ToastContainer
            closeOnClick
            draggable={false}
            toastClassName="centered-toast"
          />
        </div>
        <button
          className="data-custom-button"
          onClick={handleCvUpload}
          disabled={loading}
        >
          להעלאת קובץ קורות חיים &gt;
          <span className="recommended">(מומלץ)</span>
        </button>
      </div>
      <div className="data-col or-buffer">או</div>
      <div className="data-col">
        <button
          className={`data-custom-button ${isFormVisible ? "expand" : ""}`}
          onClick={toggleFormVisibility}
          disabled={loading}
        >
          לעדכון באמצעות טופס &gt;
          {isFormVisible && (
            <div className="data-form-container" onClick={stopPropagation}>
              <div className="data-form-row-style">
                <input
                  type="text"
                  placeholder="שם מלא *"
                  className="data-full-name data-form-input required-field"
                  onChange={handleFullNameOnChange}
                  value={fullName}
                  name="fullName"
                />
              </div>
              <div className="data-form-row-style">
                <Select
                  options={cities}
                  defaultValue={city}
                  onChange={setCity}
                  placeholder={
                    <div className="data-cities-select-placeholder">
                      עיר מגורים *
                    </div>
                  }
                  styles={customStyles}
                  className="data-cities-select form-input"
                />
              </div>
              <div className="data-form-row-style form-input">
                <input
                  type="email"
                  placeholder="אימייל"
                  className="data-email form-input"
                  onChange={handleEmailOnChange}
                  value={email}
                  name="email"
                  required
                />
              </div>
              <div className="data-form-row-style">
                <select
                  required
                  name="genders"
                  id="genders"
                  className="data-gender form-input"
                  value={gender}
                  onChange={(e) => setGender(e.target.value)}
                >
                  <option value="" disabled selected="selected" hidden>
                    מין *
                  </option>
                  <option value="100000001">זכר</option>
                  <option value="100000002">נקבה</option>
                  <option value="100000000">לא מעוניין/ת להשיב</option>
                </select>
              </div>
              <div className="data-form-row-style">
                <DatePicker
                  placeholderText="תאריך לידה"
                  showYearDropdown
                  scrollableYearDropdown
                  showMonthDropdown
                  dateFormat="dd/MM/yyyy"
                  className="form-input data-birthDate"
                  maxDate={new Date()}
                  yearDropdownItemNumber={100}
                  id="birthdate"
                  selected={birthDate}
                  onChange={handleBirthdateOnChage}
                  locale="he"
                  popperPlacement="top-start"
                  popperClassName="custom-datepicker-popper"
                  portalId="root-portal"
                />
              </div>
              <div className="data-form-row-style">
                <input
                  type="submit"
                  className="data-submit-button"
                  value="שליחה &gt;"
                  onClick={candidateDataValidation}
                />
              </div>
              <div className="data-form-row-style">
                <div className="data-col data-error-message">{message}</div>
              </div>
            </div>
          )}
        </button>
      </div>
    </div>
  );
};

export default DataFooter;
