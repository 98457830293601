import axios from "axios";
const url = "/api/process";

//If the user is interested it takes him to the apply page.
export const axiosProcess = async (processId, contactId, branchId) => {
  let info;
  await axios
    .get(`${url}/${contactId}/${processId}/${branchId}`)
    .then((res) => {
      info = res.data;
    });
  return info;
};
