import "./Header.css";
import React from "react";

const Header = () => {
  const shareFunction = () => {
    var url;
    if (window.location.href.includes("?"))
      url = window.location.href.split("?")[0];
    else url = window.location.href + "/form";

    navigator.share({
      text: "שיתוף משרה",
      url: url,
      title: "שליחה לחבר",
    });
  };

  var isProcess = window.location.href.includes("process");

  return (
    <div className="container headers-container">
      <div className="row justify-content-end header-row">
        <div className="col-8 logo-header">
          <a href="https://www.danel-jobs.co.il">
            <img
              src={require(`../../Images/Icons/Logo.png`)}
              alt="Image"
              loading="lazy"
              width="85%"
              className="logo-header-image"
            />
          </a>
        </div>
        {isProcess ? (
          <div className="col-2"></div>
        ) : (
          <div className="col-2 share-header" onClick={shareFunction}>
            <img
              src={require(`../../Images/Icons/ShareRed.png`)}
              alt="Image"
              loading="lazy"
              width="40rem"
              className="share-header-image"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
