import axios from "axios";
const url = "/api/startup";

//If the user is interested it takes him to the apply page.
export const axiosStartupPage = async (campaignId) => {
  let info;
  await axios.get(`${url}/${campaignId}`).then((res) => {
    info = res.data;
  });
  return info;
};
