import { CvContactData } from "../Classes/CvContactData";
import { NewCandidate } from "../Classes/NewCandidate";
import { DataRec } from "../Classes/DataRec";
import axios from "axios";
const url = "/api/candidate";

export const axiosCandidateConnected = async (id) => {
  let info;
  await axios.get(`${url}/${id}`).then((res) => {
    info = res.data;
  });
  return info;
};

export const axiosCreateNewCandidate = async (
  fullName,
  cityId,
  phone,
  campaignId,
  serviceOrderId,
  referrerId,
  referrerDetailId,
  subReferrerDetailId
) => {
  let info;
  let newCandidate = new NewCandidate(
    fullName,
    cityId,
    phone,
    campaignId,
    serviceOrderId,
    referrerId,
    referrerDetailId,
    subReferrerDetailId
  );

  await axios.post(`${url}`, newCandidate).then((res) => {
    info = res.data;
  });
  return info;
};

export const axiosCvUpdateCandidate = async (candidate) => {
  let info;
  let cvContactData = new CvContactData(
    candidate.contactId,
    candidate.base64,
    candidate.fileType,
    candidate.fullName,
    candidate.mobilePhone,
    candidate.stateCode,
    candidate.emailAddress,
    candidate.cityId,
    candidate.gender,
    candidate.birthDate
  );
  await axios.post(`${url}/cvUpdate`, cvContactData).then((res) => {
    info = res.data;
  });
  return info;
};

export const axiosCandidateDataUpdate = async (
  candidateId,
  fullName,
  cityId,
  mobilePhone,
  email,
  gender,
  birthDate
) => {
  let info;
  let dataRec = new DataRec(
    candidateId,
    fullName,
    cityId,
    mobilePhone,
    email,
    gender,
    birthDate
  );

  await axios.post(`${url}/updateCandidate`, dataRec).then((res) => {
    info = res.data;
  });
  return info;
};

export const axiosCorrectCandidate = async (id) => {
  let candidateId;
  await axios.get(`${url}/getCandidate/${id}`).then((res) => {
    candidateId = res.data;
  });
  return candidateId;
};
