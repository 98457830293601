import { Academic } from "./Academic";
import { DischargedSoldier } from "./DischargedSoldier";
import { General } from "./General";
import { Parent } from "./Parent";
import { Student } from "./Student";
import { DataRec } from "./DataRec";
import { KeepInTouch } from "./KeepInTouch";

export const createTemplateInstance = async (data) => {
  const { templateCode = null, templateName } = data;

  switch (templateName) {
    case "119240000":
      return new Student(templateCode);
    case "119240001":
      return new Parent(templateCode);
    case "119240002":
      return new DischargedSoldier(templateCode);
    case "119240003":
      return new Academic(templateCode);
    case "119240004":
      return new General(templateCode);
    case "DataRec":
      return new DataRec();
    case "KeepInTouch":
      return new KeepInTouch();
    default:
      return new General("1_119240004");
  }
};
