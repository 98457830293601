import { CandidateCampaignResponse } from "../Classes/CandidateCampaignResponse";
import axios from "axios";
const url = "/api/messages";

let candidate;

export const axiosCandidateInterested = async (
  candidateId,
  campaignId,
  referrerId,
  referrerDetailId,
  subReferrerDetailId
) => {
  candidate = new CandidateCampaignResponse(
    candidateId,
    campaignId,
    1,
    referrerId,
    referrerDetailId,
    subReferrerDetailId
  );
  let result;

  await axios.post(`${url}`, candidate).then((res) => {
    result = res;
  });
  return result;
};

export const axiosCandidateNotInterested = async (
  candidateId,
  campaignId,
  referrerId,
  referrerDetailId,
  subReferrerDetailId
) => {
  candidate = new CandidateCampaignResponse(
    candidateId,
    campaignId,
    2,
    referrerId,
    referrerDetailId,
    subReferrerDetailId
  );
  let result;

  await axios.post(`${url}`, candidate).then((res) => {
    result = res;
  });
  return result;
};
