import axios from "axios";
import { EmailData } from "../Classes/EmailData";
const url = "/api/email";

export const axiosSendEmail = async (
  candidateId,
  serviceOrderId,
  processId
) => {
  var emailData = new EmailData(candidateId, serviceOrderId, processId);
  let result;

  await axios.post(`${url}`, emailData).then((res) => {
    result = res;
  });
  return result;
};
