import "./ErrorPage.css";
import React from 'react'

const ErrorPage = () => {
    return (
        <div className="error-body">
            מצטערים, <br />
            המשרה שחיפשת לא קיימת. <br />
            לצפייה במשרות נוספות: <a href='https://www.danel-jobs.co.il'>Danel-Jobs</a>
        </div>
    )
}

export default ErrorPage