import config from "../../configuration";

export class Parent {
  FirstText;
  SecondText;
  ImageIdentifier;

  constructor(imageIdentifier) {
    this.FirstText = "משרה מעולה";
    this.SecondText = "להורה מעולה";
    this.setImageIdentifier(imageIdentifier);
  }

  async setImageIdentifier(imageIdentifier) {
    this.ImageIdentifier = await ImageHandler(imageIdentifier);
  }
}

const ImageHandler = async (imageIdentifier) => {
  if (imageIdentifier === config.Parent1) return "Parent/Parent1.png";
  else if (imageIdentifier === config.Parent2) return "Parent/Parent2.png";
  else throw "Wrong image identifier received.";
};
