import "./Form.css";
import React, { useState, useEffect } from "react";
import Select from "react-select";
import { axiosCreateNewCandidate } from "../../Services/CandidateService";
import { axiosGetAllCities } from "../../Services/CitiesService";
import PhoneInput from "react-phone-input-2";

const Form = (props) => {
  const [fullName, setFullName] = useState("");
  const [city, setCity] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  let [cities, setCities] = useState([]);
  let result;

  const getAllCities = async () => {
    result = await axiosGetAllCities();
    if (result) {
      setCities(renderList(result));
    }
  };

  const handleFullNameOnChange = (e) => {
    setFullName(e.target.value);
  };

  const candidateValidation = async (e) => {
    e.preventDefault();
    if (
      fullName != "" &&
      fullName.split(" ").length != 1 &&
      city.label != ("" || null) &&
      phone != "" &&
      phone != null
    ) {
      setMessage("");
      window.location =
        window.location.href.slice(0, window.location.href.indexOf("/", 10)) +
        "/thankyou";
      await axiosCreateNewCandidate(
        fullName,
        city.value,
        phone,
        props.campaignId,
        props.serviceOrderId,
        props.referrerId,
        props.referrerDetailId,
        props.subReferrerDetailId
      );
    } else if (
      fullName == null ||
      fullName == "" ||
      fullName.split(" ").length != 2
    ) {
      setMessage("הכנס שם מלא");
    } else if (city.label == ("" || null)) {
      setMessage("הכנס עיר מגורים");
    } else if (phone == "" || phone == null) {
      setMessage("הכנס מספר פלאפון");
    }
  };

  function renderList(cities) {
    result = cities
      .filter(
        (city) =>
          city.cityName != 'רילוקשיין לחו"ל' &&
          ((city) => city.code != undefined)
      )
      .map((data) => ({ label: data.cityName, value: data.cityId }));
    return result.sort(function (a, b) {
      const nameA = a.label.toUpperCase(); // ignore upper and lowercase
      const nameB = b.label.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
    });
  }

  const customStyles = {
    control: (base) => ({
      ...base,
      height: 40,
      fontSize: 16,
      border: "3px solid #e74a54",
      color: "#0f40b4",
      borderRadius: "0",
    }),
    menuList: (base) => ({
      ...base,
      maxHeight: 100,
    }),
  };

  useEffect(() => {
    getAllCities();
  }, []);

  return (
    <div className="form-container">
      <form className="register-form" onSubmit={candidateValidation}>
        <div className="col">
          <input
            type="text"
            placeholder="שם מלא"
            className="full-name"
            onChange={handleFullNameOnChange}
            value={fullName}
            name="fullName"
          />
        </div>
        <div className="col">
          <Select
            options={cities}
            defaultValue={city}
            onChange={setCity}
            placeholder={
              <div className="cities-select-placeholder">עיר מגורים</div>
            }
            styles={customStyles}
            className="cities-select"
          />
        </div>
        <div className="col phone">
          <PhoneInput
            placeholder="טלפון נייד"
            disableDropdown={true}
            country="il"
            containerClass="phoneNumber"
            autoFormat={true}
            inputStyle={{
              border: "3px solid #e74a54",
              borderWidth: "3px",
              color: "#0f40b4",
              borderRadius: "0",
              textAlign: "right",
            }}
            disableCountryCode={true}
            value={phone}
            onChange={setPhone}
            className="phone-input"
            specialLabel=""
          />
        </div>
        <div className="col">
          <button className="form-field-button" type="submit">
            שליחה<i className="form-arrow left"></i>
          </button>
        </div>
        <div className="col error-message">{message}</div>
      </form>
    </div>
  );
};

export default Form;
