export class CvContactData {
  CandidateId;
  Base64;
  FileType;
  FullName;
  MobilePhone;
  StateCode;
  EmailAddress;
  CityId;
  Gender;
  BirthDate;

  constructor(
    candidateId,
    base64,
    fileType,
    fullName,
    mobilePhone,
    stateCode,
    emailAddress,
    cityId,
    gender,
    birthDate
  ) {
    this.CandidateId = candidateId;
    this.Base64 = base64;
    this.FileType = fileType;
    this.FullName = fullName;
    this.MobilePhone = mobilePhone;
    this.StateCode = stateCode;
    this.EmailAddress = emailAddress;
    this.CityId = cityId;
    this.Gender = gender;
    this.BirthDate = birthDate;
  }
}
