import "./DataTitle.css";
import React from "react";

export const DataTitle = (props) => {
  return (
    <div className="title-block">
      <h1 className="data-first-title">{props.firstTitle}</h1>
      <h2 className="data-second-title">{props.secondTitle}</h2>
    </div>
  );
};
