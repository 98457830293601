const config = {
  Student1: "1_119240000",
  Student2: "2_119240000",
  Parent1: "1_119240001",
  Parent2: "2_119240001",
  DischargedSoldier1: "1_119240002",
  DischargedSoldier2: "2_119240002",
  Academic1: "1_119240003",
  Academic2: "2_119240003",
  Academic3: "3_119240003",
  General1: "1_119240004",
  General2: "2_119240004",
  General3: "3_119240004",
  General4: "4_119240004",
  General5: "5_119240004",
  General6: "6_119240004",
  General7: "7_119240004",
  General8: "8_119240004",
  General9: "9_119240004",
  SmsGeneral: "1_119240005",
};

export default config;
