export class EmailData {
  CandidateId;
  ServiceOrderId;
  ProcessId;

  constructor(candidateId, serviceOrderId, processId) {
    this.CandidateId = candidateId;
    this.ServiceOrderId = serviceOrderId;
    this.ProcessId = processId;
  }
}
