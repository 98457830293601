export class KeepInTouch {
  FirstText;
  SecondText;
  ImageIdentifier;

  constructor() {
    this.FirstText = "מעולה!";
    this.SecondText = "נהיה איתך בקשר ממש בקרוב.";
    this.setImageIdentifier();
  }

  async setImageIdentifier() {
    this.ImageIdentifier = await ImageHandler();
  }
}

const ImageHandler = async () => {
  return "DataRec/DataRec.png";
};
