import React, { useEffect, useState } from "react";
import "./Button.css";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Button = (props) => {
  const [buttonPath, setButtonPath] = useState("");

  useEffect(() => {
    setButtonPath(require(`../../Images/Icons/${props.path}`));
  }, [props.path]);

  return (
    <div className="clickable-div" onClick={props.onClick}>
      <LazyLoadImage
        src={buttonPath}
        width={props.width}
        height={props.height}
        loading="lazy"
        alt={buttonPath}
      />
    </div>
  );
};

export default Button;
