import "./DataRec.css";
import React, { useEffect, useState } from "react";
import { DataTitle } from "../../Components/DataTitle/DataTitle";
import { Paper } from "@material-ui/core";
import { createTemplateInstance } from "../../Classes/Templates/Template";
import Skeleton from "react-loading-skeleton";
import DataDescription from "../../Components/DataDescription/DataDescription";
import DataFooter from "../../Components/DataFooter/DataFooter";

const DataRec = () => {
  const [image, setImage] = useState("");
  const [template, setTemplate] = useState("");

  const paperStyle = {
    height: "auto",
    width: "auto",
    maxWidth: "100%",
    margin: "2% 2%",
    boxShadow: "0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24)",
    borderRadius: "5px",
  };

  const data = {
    templateName: "DataRec",
  };

  const loadPage = async () => {
    await templateHandler(data);
  };

  const templateHandler = async (data) => {
    var template = await createTemplateInstance(data);
    setImage(template.ImageIdentifier);
    setTemplate(template);
  };

  useEffect(() => {
    loadPage();
  }, []);

  return (
    <div>
      <Paper style={paperStyle}>
        <div className="dataRec-logo-header">
          <a href="https://www.danel-jobs.co.il">
            <img
              src={require(`../../Images/Icons/Logo.png`)}
              alt="Image"
              loading="lazy"
              width="50%"
              className="data-logo-header-image"
            />
          </a>
        </div>
        <div className="col dataRec-title">
          {template ? (
            <DataTitle
              firstTitle={template.FirstText}
              secondTitle={template.SecondText}
            />
          ) : (
            <Skeleton
              count={2}
              height={25}
              direction="rtl"
              width="80%"
              style={{ lineHeight: "30px" }}
            />
          )}
        </div>
        <div className="col main-image">
          {image ? (
            <img
              src={require(`../../Images/${image}`)}
              width="90%"
              height="100%"
              alt=""
            />
          ) : (
            <Skeleton
              count={1}
              height={100}
              width="90%"
              direction="rtl"
              style={{ lineHeight: "100px" }}
            />
          )}
          <DataDescription />
          <DataFooter />
        </div>
      </Paper>
    </div>
  );
};

export default DataRec;
