import config from "../../configuration";

export class Student {
  FirstText;
  SecondText;
  ImageIdentifier;

  constructor(imageIdentifier) {
    this.FirstText = "היי סטודנטים,";
    this.SecondText = "יש לנו משרה לתקופת הלימודים";
    this.setImageIdentifier(imageIdentifier);
  }

  async setImageIdentifier(imageIdentifier) {
    this.ImageIdentifier = await ImageHandler(imageIdentifier);
  }
}

const ImageHandler = async (imageIdentifier) => {
  if (imageIdentifier === config.Student1) return "Student/Student1.png";
  else if (imageIdentifier === config.Student2) return "Student/Student2.png";
  else if (imageIdentifier === config.Student3) return "Student/Student3.png";
  else if (imageIdentifier === config.Student4) return "Student/Student4.png";
  else throw "Wrong image identifier received.";
};
