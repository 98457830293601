import "./DataDescription.css";
import React from "react";

const DataDescription = (props) => {
  return (
    <div className="container description-container">
      <div className="data-description-body">
        בהמשך להגשת המועמדות שלך למשרה בדנאל משאבי אנוש
        <br /> וכדי שנוכל לתת לך שירות מיטבי, נשמח לעדכון הפרטים שלך אצלנו.
      </div>
    </div>
  );
};

export default DataDescription;
